.header {
  padding: 26px 26px;
  background: rgba(233, 240, 253, 0.3);
  backdrop-filter: blur(7.5px);

  .container {
    flex-wrap: wrap;

    nav {
      display: flex;
      flex-direction: row;

      button:first-child {
        margin-right: 24px;
      }
    }
  }


  .wallet-connect-text {
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
  }
}

.logo img {
  margin-right: 14px;
}

.navigation-menu {
  height: 24px;
  width: 24px;
  margin: auto 0 auto auto;
  display: none;
}

.offcanvas-end {
  box-shadow: 0 5px 10px 0 #301F851A;
  border: 0;
  width: 285px!important;
}

.offcanvas-body {
  box-shadow: 0 5px 10px 0 #301F851A;
  border: 0;
  padding: 34px 20px;

  .navigation-menu {
    margin-bottom: 20px;
  }

  .mobile-navigation {

    .wallet-connect-mobile {
      overflow: hidden;
      white-space: nowrap;
      display: block;
      text-overflow: ellipsis;
      max-width: 200px;
    }

    a:hover {
      color: #172133!important;
    }


    .navigation-link {
      text-decoration: none;
      margin-bottom: 24px;
    }
  }
}


