.description-container {
  padding-top: 97.5px;
  padding-bottom: 94px;

  .seed-sale_container {
    a {
      text-decoration: none;
      cursor: pointer;
    }

    a:hover {
      background-color: #4482FF;
      color: #FFFFFF;
    }
  }

  .timer-container {
    width: 651px;
    margin: 180px auto 100px;

    .timer_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: 0 10px 10px rgba(80, 134, 224, 0.15);
      border-radius: 22px;
      padding: 46px 29px;

      p:first-child {
        width: 141px;
        min-height: 78px;
        text-align: center;
      }

      p:last-child {
        text-transform: uppercase;
      }
    }
  }

  .condition_container {
    margin-top: 169.5px;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;

  }

  .tokenomic_container {
    margin-top: 100px;
    justify-content: space-between;
    flex-wrap: wrap;

    .tokenomic_image {
      width: 673px;
      height: 538px;
    }

    .right-section {
      display: flex;
      align-items: center;

      .table-token {
        width: 556px;
        box-shadow: 0 15px 50px rgba(80, 134, 224, 0.2);
        background: #FFFFFF;
        border-radius: 20px;

        .header-table {
          background: #4482FF;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
          padding: 20px 0;
        }

        .content-table {
          padding: 30px 32px;

          .item-table p:last-child {
            width: 115px;
          }

          .item-table:not(:last-child):not(:first-child) {
            border-bottom: 1px solid #92B4F6;
            padding: 11px 0 21px;
          }

          .item-table:first-child {
            padding-bottom: 11px;
            border-bottom: 1px solid #92B4F6;
          }

          .item-table:last-child {
            padding-top: 11px;
          }
        }
      }
    }


  }

}